import styles from './styles.module.scss';
import { CallCenter } from '@/components/CallCenter';
import { Email } from '@/components/Email';

export const Contacts = () => (
  <div className={styles.contactsWrapper}>
    <CallCenter />
    <Email />
  </div>
);
