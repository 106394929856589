import { useRef } from 'react';
import { BUTTON_THROTTLE_DELAY } from '@/common/constants/time';

type UseThrottle = (
  callback?: (args: any) => void,
  delay?: number
) => (args: any) => void;

export const useThrottle: UseThrottle = (
  callback,
  delay = BUTTON_THROTTLE_DELAY
) => {
  const lastCallRef = useRef(0);

  return (...args) => {
    const now = Date.now();

    if (now - lastCallRef.current >= delay) {
      lastCallRef.current = now;
      callback?.(...args);
    }
  };
};
