import { HEADERS_JSON } from '@/common/api/constants';
import { ProfilePostProps } from '@/common/api/requests/profile/types';
import { CommonFetchRequest, FetchResponse } from '@/common/api/types';
import { handleErrorMessage, responseHandler } from '@/common/api/utils';
import { API_URL } from '@/config';

export const fetchProfile: CommonFetchRequest<{ token: string }> = async ({
  setErrorMessage,
  token,
}): Promise<FetchResponse | void> => {
  const response = await fetch(`${API_URL}/cabinet/profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
  });
  return await responseHandler({ response, setErrorMessage });
};

export const postProfile: CommonFetchRequest<{
  token: string;
  profile: ProfilePostProps;
}> = async ({ setErrorMessage, token, profile }) => {
  const response = await fetch(`${API_URL}/cabinet/profile`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
    body: JSON.stringify(profile),
  });
  return await responseHandler({ response, setErrorMessage });
};

export const postBankAccount: CommonFetchRequest<{
  token: string;
  bankAccount: string;
}> = async ({ setErrorMessage, token, bankAccount }) => {
  const response = await fetch(`${API_URL}/cabinet/add-bank-account`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
    body: JSON.stringify({ iban: bankAccount }),
  });

  return await responseHandler({
    response,
    setErrorMessage,
    customHandler: handleErrorMessage,
  });
};
