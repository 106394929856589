import { useEffect, useState } from 'react';
import RU_LANG from './compiled/ru.json';
import { Locales } from '@/lang/types';

const getMessages = async (lang: Locales) => {
  if (lang === Locales.ru) {
    return RU_LANG;
  } else {
    return await import('./compiled/kz.json');
  }
};

type UseMessages = (lang: Locales) => {
  messages?: Record<string, string>;
};

export const useMessages: UseMessages = (lang) => {
  const [messages, setMessages] = useState<Record<string, string>>(RU_LANG);

  useEffect(() => {
    (async function () {
      const messages = await getMessages(lang);
      setMessages(messages);
    })();
  }, [lang]);

  return {
    messages,
  };
};
