import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const License = () => (
  <div className={styles.footerLicense}>
    <p>
      <FormattedMessage defaultMessage="© 2024 selfiecredit.kz | ТОО «МФО «CreditNova»" />
      <br />
      <FormattedMessage defaultMessage="№ лицензии 02.23.0014.М от 22.05.2023" />
    </p>
    <p>
      <FormattedMessage defaultMessage="Прием заявок: круглосуточно и без выходных." />
      <br />
      <FormattedMessage defaultMessage="Рассмотрение и отправка денег: Пн-Пт 09:00-18:00" />
    </p>
  </div>
);
