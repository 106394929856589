import { useEffect } from 'react';
import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const devMode = process.env.ENV_MODE === 'develop';

if (!devMode) {
  init({
    dsn: 'https://f0d6ea9eac68985bc60092ac3d6f4d7c@o4507096952668160.ingest.de.sentry.io/4508044940148816',
    environment: 'production',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
