import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { MoneyStepsFAQ } from '@/components/MoneyStepsFAQ';

export const HowItWorks = () => {
  return (
    <section>
      <div className={cn('container', styles.container)}>
        <h2>
          <FormattedMessage defaultMessage="Как это работает?" />
        </h2>
        <MoneyStepsFAQ />
      </div>
    </section>
  );
};
