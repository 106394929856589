import styles from './styles.module.scss';
import { About } from '@/entities/HomePage/About';
import { Advantages } from '@/entities/HomePage/Advantages';
import { Banner } from '@/entities/HomePage/Banner';
import { BannerBottom } from '@/entities/HomePage/BannerBottom';
import { HowItWorks } from '@/entities/HomePage/HowItWorks';

export const HomePage = () => {
  return (
    <div className={styles.homePage}>
      <Banner />
      <HowItWorks />
      <Advantages />
      <BannerBottom />
      <About />
    </div>
  );
};
