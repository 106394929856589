import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '@/common/hooks/useAppContext';
import Modal from '@/components/Modal';

export const ModalError = () => {
  const { errorMessage, setErrorMessage } = useContext(AppContext);
  const onCloseErrorModal = () => {
    setErrorMessage(null);
  };
  return (
    <Modal
      title={<FormattedMessage defaultMessage="Ошибка" />}
      show={!!errorMessage}
      onClose={onCloseErrorModal}
    >
      <p>{errorMessage}</p>
    </Modal>
  );
};
