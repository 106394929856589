import { useMemo } from 'react';
import {
  LOAN_AMOUNT_INITIAL,
  LOAN_DAYS_PERIOD_INITIAL,
} from '@/common/constants/loan';

type UseLoanInitialValues = () => {
  loanAmountInitial: number;
  loanPeriodInitial: number;
  loanAmountInitialString: string;
  loanPeriodInitialString: string;
};

export const useLoanInitialValues: UseLoanInitialValues = () => {
  const amountInitial =
    localStorage.getItem('loanAmount') || String(LOAN_AMOUNT_INITIAL);
  const periodInitial =
    localStorage.getItem('loanDaysPeriod') || String(LOAN_DAYS_PERIOD_INITIAL);

  return useMemo(() => {
    return {
      loanAmountInitial: parseFloat(amountInitial),
      loanPeriodInitial: parseFloat(periodInitial),
      loanAmountInitialString: amountInitial,
      loanPeriodInitialString: periodInitial,
    };
  }, []);
};
