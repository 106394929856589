import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router';
import { AppContext, useAppContext } from '@/common/hooks/useAppContext';
import { Locales } from '@/lang/types';
import { useMessages } from '@/lang/useMessages';
import router from '@/router';
import { PageSpinner } from '@/shared/PageSpinner';

const queryClient = new QueryClient();

export const App = () => {
  const { lang, initialAppContext } = useAppContext();
  const { messages } = useMessages(lang);

  return (
    <AppContext.Provider value={initialAppContext}>
      <IntlProvider
        locale={lang}
        defaultLocale={Locales.ru}
        messages={messages}
        onError={() => null}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} fallbackElement={<PageSpinner />} />
        </QueryClientProvider>
      </IntlProvider>
    </AppContext.Provider>
  );
};
