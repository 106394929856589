import { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { useThrottle } from '@/common/hooks/useThrottle';
import { Spinner } from '@/shared/Spinner';

interface Props {
  id: string;
  children: ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit';
  outlined?: boolean;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  form?: string;
}

export const MainButton: FC<Props> = ({
  id,
  onClick,
  children,
  type = 'button',
  outlined = false,
  className,
  loading = false,
  disabled = false,
  form,
}) => {
  const throttledClick = useThrottle(onClick);
  return (
    <button
      id={`${id}-button`}
      type={type}
      form={form}
      className={cn(
        styles.mainButton,
        { [styles.outlined]: outlined },
        { [styles.disabled]: disabled || loading },
        className
      )}
      onClick={throttledClick}
      disabled={disabled || loading}
    >
      <span className={styles.text}>{children}</span>
      {loading && (
        <div className={styles.spinnerWrapper}>
          <Spinner size={32} light narrow />
        </div>
      )}
    </button>
  );
};
