import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import LogoSelfie from '@/common/svg/logo_selfie_bw.svg';
import LogoSelfieText from '@/common/svg/logo_selfie_text_bw.svg';

export const Logo = () => (
  <Link id="footer-logo-link" className={styles.logo} to="/">
    <LogoSelfie />
    <LogoSelfieText />
  </Link>
);
