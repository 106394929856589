import { FC } from 'react';
import styles from './styles.module.scss';
import PhoneIcon from '@/common/svg/call-calling.svg';

interface Props {
  showSubtitle?: boolean;
}

export const CallCenter: FC<Props> = ({ showSubtitle = true }) => {
  return (
    <div className={styles.contacts}>
      <PhoneIcon />
      <div className={styles.phoneBlock}>
        <a id="phone-link" className={styles.phoneLink} href="tel:+77003177949">
          +7 700 317 79 49
        </a>
        {showSubtitle && <span className={styles.subTitle}>call-center</span>}
      </div>
    </div>
  );
};
