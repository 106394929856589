import { FC, MouseEvent, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import { useStopScroll } from '@/common/hooks/useStopScroll';
import { CloseCrossIcon } from '@/common/icons/CloseCrossIcon';
import { IconButton } from '@/shared/IconButton';
import { GRAY_COLOR } from '@/styles/colorsJS';

interface Props {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: ReactElement | string;
  modalClassName?: string;
  mobileFullSize?: boolean;
}

const MODAL_ID = 'modal-window';

const Modal: FC<Props> = ({
  show,
  children,
  onClose,
  title,
  modalClassName,
  mobileFullSize = false,
}) => {
  useStopScroll(show);

  const onBackgroundClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(`#${MODAL_ID}`)) {
      onClose();
    }
  };

  return (
    <>
      {show &&
        createPortal(
          <div
            className={cn(styles.background, {
              [styles.mobileFullSize]: mobileFullSize,
            })}
            onClick={onBackgroundClick}
          >
            <div id={MODAL_ID} className={cn(styles.modal, modalClassName)}>
              <div className={styles.header}>
                {!!title && <h2 className={styles.title}>{title}</h2>}
                <div className={styles.crossButtonWrapper}>
                  <IconButton
                    id={`modal-${title || ''}`}
                    onClick={onClose}
                    Icon={CloseCrossIcon}
                    iconColor={GRAY_COLOR}
                  />
                </div>
              </div>
              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Modal;
