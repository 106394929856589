import { lazy, Suspense } from 'react';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import BannerBottomMobile from '@/entities/HomePage/BannerBottom/mobile';

const BannerBottomDesktop = lazy(() => import('./desktop'));

export const BannerBottom = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile && <BannerBottomMobile />}
      {!isMobile && (
        <Suspense>
          <BannerBottomDesktop />
        </Suspense>
      )}
    </>
  );
};
