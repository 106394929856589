import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import GirlImage from './assets/advantages-girl.webp';
import styles from './styles.module.scss';
import { TENGE_LABEL } from '@/common/constants/common';
import {
  LOAN_AMOUNT_MAX,
  LOAN_AMOUNT_MIN,
  LOAN_DAY_RATE_MIN_PERCENT,
  LOAN_DAYS_PERIOD_MAX,
  LOAN_DAYS_PERIOD_MIN,
  PERIOD_LABEL,
} from '@/common/constants/loan';
import AccountIcon from '@/common/svg/account-icon-square.svg';
import BanknotesIcon from '@/common/svg/banknots.svg';
import HandLikeIcon from '@/common/svg/hand-like.svg';
import PercentIcon from '@/common/svg/percent-square-icon.svg';
import RefreshIcon from '@/common/svg/refresh.svg';
import { formatAmount } from '@/common/utils/numbers';
import { BenefitItem } from '@/components/BenefitItem';

export const Advantages = () => {
  return (
    <section className={styles.section}>
      <div className={cn(styles.imageWrapper, 'mobile-display')}>
        <img src={GirlImage} alt="girl" />
      </div>
      <div className={cn('container', styles.container)}>
        <h2>
          <FormattedMessage defaultMessage="Наши преимущества" />
        </h2>
        <div className={styles.main}>
          <div className={styles.content}>
            <BenefitItem
              Icon={BanknotesIcon}
              title={
                <FormattedMessage defaultMessage="Selfie вместо анкеты — деньги вместо ожидания!" />
              }
              description={
                <FormattedMessage defaultMessage="SelfieCredit предоставляет вам не только средства, но и удобство и свободу управления вашими финансами. Ваше финансовое будущее в одном селфи" />
              }
            />
            <div className={styles.row}>
              <BenefitItem
                Icon={PercentIcon}
                title={
                  <FormattedMessage
                    defaultMessage="Первый кредит — всего под {rate}%"
                    values={{ rate: LOAN_DAY_RATE_MIN_PERCENT }}
                  />
                }
                description={
                  <FormattedMessage defaultMessage="Специальное предложение для новых клиентов: первый микрокредит почти без процентов!" />
                }
              />
              <BenefitItem
                Icon={HandLikeIcon}
                title={
                  <FormattedMessage defaultMessage="Минимум данных — максимум удобств" />
                }
                description={
                  <FormattedMessage defaultMessage="Для получения микрокредита достаточно ввести только ИИН и номер телефона, а также сделать селфи для биометрической верификации" />
                }
              />
            </div>
            <div className={styles.row}>
              <BenefitItem
                Icon={RefreshIcon}
                title={<FormattedMessage defaultMessage="Гибкие условия" />}
                description={
                  <FormattedMessage
                    defaultMessage="Кредиты доступны на сумму от {minAmount}{amountLabel} до {maxAmount}{amountLabel} на срок от {minPeriod} до {maxPeriod} {periodLabel}. Выбирайте удобные для себя условия"
                    values={{
                      minAmount: formatAmount({ number: LOAN_AMOUNT_MIN }),
                      maxAmount: formatAmount({ number: LOAN_AMOUNT_MAX }),
                      amountLabel: TENGE_LABEL,
                      minPeriod: LOAN_DAYS_PERIOD_MIN,
                      maxPeriod: LOAN_DAYS_PERIOD_MAX,
                      periodLabel: PERIOD_LABEL,
                    }}
                  />
                }
              />
              <BenefitItem
                Icon={AccountIcon}
                title={
                  <FormattedMessage defaultMessage="Безопасность и надежность" />
                }
                description={
                  <FormattedMessage defaultMessage="Используем передовые технологии биометрической верификации, чтобы гарантировать защиту ваших данных и уверенность в безопасности" />
                }
              />
            </div>
          </div>
          <div className={cn(styles.imageWrapper, 'desktop-display')}>
            <img src={GirlImage} alt="girl" />
          </div>
        </div>
      </div>
    </section>
  );
};
