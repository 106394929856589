import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { ProfileCard } from '@/components/ProfileCard';
import { Navigation } from '@/entities/Layout/Cabinet/ui/Navigation';
import { CabinetCard } from '@/shared/CabinetCard';

interface Props {
  children: ReactNode;
}

const MobileLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <section className={styles.section}>
        <div className={styles.profileCardWrapper}>
          <CabinetCard>
            <ProfileCard />
          </CabinetCard>
        </div>
        {children}
      </section>
      <Navigation />
    </>
  );
};

export default MobileLayout;
