import { lazy, Suspense, useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useRouteError } from 'react-router';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import { useScrollToTopOnMount } from '@/common/hooks/useScrollToTopOnMount';
import { Footer } from '@/entities/Footer';
import { Header } from '@/entities/Header';
import { PageSpinner } from '@/shared/PageSpinner';

const ErrorPageMobile = lazy(() => import('./mobile'));
const ErrorPageDesktop = lazy(() => import('./desktop'));

export const ErrorPage = () => {
  useScrollToTopOnMount();
  const isMobile = useIsMobile();
  const error = useRouteError() as Error;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <>
      <Header />
      {isMobile && (
        <Suspense fallback={<PageSpinner />}>
          <ErrorPageMobile />
        </Suspense>
      )}
      {!isMobile && (
        <Suspense fallback={<PageSpinner />}>
          <ErrorPageDesktop />
        </Suspense>
      )}
      <Footer />
    </>
  );
};
