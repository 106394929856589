type FormatNumber = ({
  number,
  maximumFractionDigits,
}: {
  number: number;
  maximumFractionDigits?: number;
}) => string;

export const formatAmount: FormatNumber = ({
  number,
  maximumFractionDigits = 1,
}) => {
  const isValid = isFinite(number);
  if (isValid) {
    return Intl.NumberFormat('ru-RU', {
      style: 'decimal',
      maximumFractionDigits,
    }).format(number);
  } else {
    return 'no data';
  }
};

type GenerateRandomSize = ({
  max,
  min,
}: {
  max: number;
  min: number;
}) => number;

export const generateRandomSize: GenerateRandomSize = ({ max, min }) => {
  return max - ((Math.random() * 1000) % min);
};
