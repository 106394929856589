type CalcRepayment = ({
  amount,
  daysPeriod,
}: {
  amount: number;
  daysPeriod: number;
}) => number;

export const calcRepayment: CalcRepayment = ({ amount, daysPeriod }) => {
  return Math.round(amount + (amount / 10000) * daysPeriod);
};
