import { FormattedMessage } from 'react-intl';

export const tags = [
  <FormattedMessage key={1} defaultMessage="Деньги до зарплаты" />,
  <FormattedMessage key={2} defaultMessage="Займ на карту" />,
  <FormattedMessage key={3} defaultMessage="Кредит без залога" />,
  <FormattedMessage key={4} defaultMessage="Онлайн займ" />,
  <FormattedMessage
    key={5}
    defaultMessage="Кредит без проверки кредитной истории"
  />,
  <FormattedMessage key={6} defaultMessage="Микрозаймы на карту" />,
];
