import { wrapCreateBrowserRouter } from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { LayoutBase } from '@/entities/Layout/Base';
import { LayoutPlain } from '@/entities/Layout/Plain';
import { ErrorPage } from '@/pages/ErrorPage';
import { cabinetRouter } from '@/router/cabinetRouter';
import { commonRouter } from '@/router/commonRouter';
import { indexRedirect } from '@/router/loaders/indexRedirect';
import { rejectedLoader } from '@/router/loaders/rejectedLoader';
import { signupRouter } from '@/router/signupRouter';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export default sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="*" loader={indexRedirect} />
      {cabinetRouter}
      {signupRouter}
      {commonRouter}
      <Route element={<LayoutPlain />} errorElement={<ErrorPage />}>
        <Route path="/payment" lazy={() => import('@/pages/Payment')} />
      </Route>
      <Route element={<LayoutBase />} errorElement={<ErrorPage />}>
        <Route
          path="/rejected"
          loader={rejectedLoader}
          lazy={() => import('@/pages/Rejected')}
        />
      </Route>
      <Route path="/statements" lazy={() => import('@/pages/Statements')} />
    </>
  )
);
