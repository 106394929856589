export const calcFileSizeMb = (numberOfBytes: number) => {
  const rawNumber = numberOfBytes / 1024 ** 2;
  const result = Math.round(rawNumber * 100) / 100;
  if (result < 0.01) {
    return 0.01;
  }
  return result;
};

export const getFileSize = async (fileUrl: string) => {
  const response = await fetch(fileUrl, { method: 'HEAD' });
  const contentLength = response.headers.get('content-length');
  return contentLength ? parseInt(contentLength, 10) : 0;
};
