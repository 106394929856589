import { lazy, Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { CloseCrossIcon } from '@/common/icons/CloseCrossIcon';
import LogoSelfie from '@/common/svg/logo_selfie.svg';
import LogoSelfieText from '@/common/svg/logo_selfie_text.svg';
import BurgerIcon from '@/common/svg/menu.svg';
import { LangSwitcher } from '@/components/LangSwitcher';

const Drawer = lazy(() => import('@/entities/Drawer'));

const MobileHeader = () => {
  const [drawerRendered, setDrawerRendered] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    if (!drawerRendered) {
      setDrawerRendered(true);
      setTimeout(() => setDrawerVisible(true), 50);
    }
    if (drawerRendered) {
      setDrawerVisible((prev) => !prev);
    }
  };

  const onLogoClick = () => {
    if (drawerVisible) {
      toggleDrawer();
    }
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.rightSide}>
          <LangSwitcher />
          <Link
            id="logo-link"
            className={styles.logoWrapper}
            to="/"
            onClick={onLogoClick}
          >
            <LogoSelfie />
            <LogoSelfieText />
          </Link>
        </div>
        <button onClick={toggleDrawer}>
          {!drawerVisible && <BurgerIcon />}
          {drawerVisible && <CloseCrossIcon />}
        </button>
      </header>
      {drawerRendered && (
        <Suspense>
          <Drawer visible={drawerVisible} toggleHandler={toggleDrawer} />
        </Suspense>
      )}
    </>
  );
};

export default MobileHeader;
