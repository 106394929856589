export const HEADERS_JSON = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const STATUS = {
  SUCCESS: 200,
  CLIENT_ERRORS: 400,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
  REJECTED: 444,
  SERVER_ERRORS: 500,
};

export const QUERY_KEYS = {
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_LATEST_LEAD: 'FETCH_LATEST_LEAD',
  FETCH_LOAN_INFO: 'FETCH_LOAN_INFO',
  FETCH_LOAN_DOCUMENTS: 'FETCH_LOAN_DOCUMENTS',
  FETCH_FAMILY_STATUSES: 'FETCH_FAMILY_STATUSES',
  FETCH_COMPLAINT_REFERENCES: 'FETCH_COMPLAINT_REFERENCES',
  FETCH_GENDER_STATUSES: 'FETCH_GENDER_STATUSES',
  FETCH_RELATIVE_STATUSES: 'FETCH_RELATIVE_STATUSES',
  FETCH_LEADS_HISTORY: 'FETCH_LEADS_HISTORY',
  FETCH_LOANS_HISTORY: 'FETCH_LOANS_HISTORY',
  FETCH_PAYMENTS_HISTORY: 'FETCH_PAYMENTS_HISTORY',
};

export const STALE_TIME_SHORT = 1200;
export const STALE_TIME_ONE_DAY = 1000 * 60 * 60 * 24;
