import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { documentsList } from '@/components/DocumentsModal/model';
import Modal from '@/components/Modal';
import { DownloadableItem } from '@/shared/DownloadableItem';

interface Props {
  show: boolean;
  onClose: () => void;
}

export const DocumentsModal: FC<Props> = ({ show, onClose }) => (
  <Modal
    show={show}
    onClose={onClose}
    title={<FormattedMessage defaultMessage="Документы" />}
    modalClassName={styles.modal}
    mobileFullSize
  >
    <div className={styles.content}>
      {documentsList.map(({ title, link }, index) => (
        <DownloadableItem key={index} link={link} title={title} />
      ))}
    </div>
  </Modal>
);
