import styles from './styles.module.scss';
import EmailIcon from '@/common/svg/email.svg';

export const Email = () => (
  <div className={styles.email}>
    <EmailIcon />
    <a id="email-link" href="mailto:info@selfiecredit.kz">
      info@selfiecredit.kz
    </a>
  </div>
);
