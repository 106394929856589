import { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { TENGE_LABEL } from '@/common/constants/common';
import {
  LOAN_AMOUNT_MAX,
  LOAN_AMOUNT_MIN,
  LOAN_DAYS_PERIOD_MAX,
  LOAN_DAYS_PERIOD_MIN,
  PERIOD_LABEL,
} from '@/common/constants/loan';
import { useLoanInitialValues } from '@/common/hooks/useLoanInitialValues';
import CreditCard from '@/common/svg/credit-card-checked.svg';
import { calcRepayment } from '@/common/utils/loan';
import { formatAmount } from '@/common/utils/numbers';
import { SliderChunk } from '@/components/SliderChunk/ui';
import { SliderSmooth } from '@/components/SliderSmooth/ui';

interface Props {
  className?: string;
}

export const CreditCalculator: FC<Props> = ({ className }) => {
  const { loanAmountInitial, loanPeriodInitial } = useLoanInitialValues();

  const [amount, setAmount] = useState<number>(loanAmountInitial);
  const [daysPeriod, setDaysPeriod] = useState<number>(loanPeriodInitial);

  const repayment = useMemo(() => {
    return calcRepayment({ amount, daysPeriod });
  }, [amount, daysPeriod]);

  const handleCreditAmount = (value: number) => {
    setAmount(value);
    localStorage.setItem('loanAmount', String(value));
  };

  const handleCreditPeriod = (value: number) => {
    setDaysPeriod(value);
    localStorage.setItem('loanDaysPeriod', String(value));
  };

  return (
    <div className={cn(styles.calculatorWrapper, className)}>
      <SliderSmooth
        title={<FormattedMessage defaultMessage="Сумма микрокредита" />}
        minValue={LOAN_AMOUNT_MIN}
        maxValue={LOAN_AMOUNT_MAX}
        initialValue={loanAmountInitial}
        handlerValue={handleCreditAmount}
        sign={TENGE_LABEL}
      />
      <SliderChunk
        title={<FormattedMessage defaultMessage="Срок микрокредита" />}
        minValue={LOAN_DAYS_PERIOD_MIN}
        maxValue={LOAN_DAYS_PERIOD_MAX}
        initialValue={loanPeriodInitial}
        handlerValue={handleCreditPeriod}
        sign={PERIOD_LABEL}
      />
      <ul className={styles.infoBlock}>
        <li className={styles.row}>
          <div className={styles.left}>
            <CreditCard />
            <span>
              <FormattedMessage defaultMessage="К возврату" />
            </span>
          </div>
          <span className={styles.value}>
            {formatAmount({ number: repayment })} ₸
          </span>
        </li>
      </ul>
    </div>
  );
};
