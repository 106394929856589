import { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface CardBlankProps {
  children: ReactNode;
  className?: string;
}

export const CardBlank: FC<CardBlankProps> = ({ children, className = '' }) => {
  return <div className={cn(styles.cardWrapper, className)}>{children}</div>;
};
