import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import { Spinner } from '@/shared/Spinner';

export const PageSpinner = () =>
  createPortal(
    <div className={styles.pageLoader}>
      <Spinner />
    </div>,
    document.body
  );
