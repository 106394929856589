import { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export const CabinetCard: FC<Props> = ({ children, className }) => (
  <div className={cn(styles.cabinetCard, className)}>{children}</div>
);
