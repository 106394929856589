import { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { useThrottle } from '@/common/hooks/useThrottle';

interface IconButtonProps {
  id: string;
  Icon: FC<{ color?: string }>;
  onClick: () => void;
  iconColor?: string;
  className?: string;
}

export const IconButton: FC<IconButtonProps> = ({
  id,
  Icon,
  onClick,
  iconColor,
  className,
}) => {
  const throttledClick = useThrottle(onClick);
  return (
    <button
      id={`${id}-icon-button`}
      onClick={throttledClick}
      className={cn(styles.iconButton, className)}
    >
      <Icon color={iconColor} />
    </button>
  );
};
