import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

interface CoordinatesProps {
  startX: number;
  startY: number;
  startLength: number;
  stepPixels: number;
  sliderWidth: number;
}

type UseSlider = ({
  sliderWrapperRef,
  sliderRef,
  maxValue,
  minValue,
  initialValue,
  step,
  handler,
  THUMB_ID,
}: {
  sliderWrapperRef: MutableRefObject<HTMLDivElement | null>;
  sliderRef: MutableRefObject<HTMLDivElement | null>;
  maxValue: number;
  minValue: number;
  initialValue: number;
  step: number;
  handler: (value: number) => void;
  THUMB_ID: string;
}) => {
  onSliderClick: (event: any) => void;
  onMouseDown: (event: MouseEvent) => void;
  onTouchStart: (event: any) => void;
};

export const useSlider: UseSlider = ({
  sliderWrapperRef,
  sliderRef,
  maxValue,
  minValue,
  initialValue,
  step,
  handler,
  THUMB_ID,
}) => {
  const coordinates = useRef<CoordinatesProps>({
    startX: 0,
    startY: 0,
    startLength: 0,
    stepPixels: 0,
    sliderWidth: 0,
  }).current;

  useEffect(() => {
    if (sliderWrapperRef.current && sliderRef.current) {
      const sliderWidth = sliderWrapperRef.current.clientWidth;
      coordinates.sliderWidth = sliderWidth;

      const valueRange = maxValue - minValue;
      const stepPixels = (sliderWidth / valueRange) * step;
      coordinates.stepPixels = stepPixels;

      const initialSliderValue =
        initialValue > minValue ? initialValue - minValue : 0;

      const initialSliderWidth = (initialSliderValue / step) * stepPixels;

      sliderRef.current.style.width = `${initialSliderWidth}px`;
    }
  }, [
    maxValue,
    minValue,
    initialValue,
    step,
    coordinates,
    sliderWrapperRef,
    sliderRef,
  ]);

  const onSliderClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (!target.closest(`#${THUMB_ID}`)) {
        const { x: sliderStartX } = target.getBoundingClientRect();
        const touchX = event.clientX;
        const distanceRaw = touchX - sliderStartX;
        const stepsAmount = Math.ceil(distanceRaw / coordinates.stepPixels);
        const distance = stepsAmount * coordinates.stepPixels;
        const newValue = stepsAmount * step + minValue;

        if (newValue >= minValue && sliderRef.current) {
          sliderRef.current.style.width = `${distance}px`;
          handler(newValue);
        }

        if (newValue < minValue && sliderRef.current) {
          const distance = (minValue / step) * coordinates.stepPixels;
          sliderRef.current.style.width = `${distance}px`;
          handler(minValue);
        }
      }
    },
    [sliderRef, THUMB_ID, coordinates.stepPixels, handler, minValue, step]
  );

  const onMove = useCallback(
    (clientX: number) => {
      const initialWidth = coordinates.startLength;
      const initialStepsAmount = Math.ceil(
        initialWidth / coordinates.stepPixels
      );
      const stepsAmount = Math.ceil(
        (clientX - coordinates.startX) / coordinates.stepPixels
      );
      const newValue = (initialStepsAmount + stepsAmount) * step + minValue;

      let distance =
        (initialStepsAmount + stepsAmount) * coordinates.stepPixels;

      const trashEnd = coordinates.sliderWidth - coordinates.stepPixels;
      if (distance >= trashEnd && distance <= coordinates.sliderWidth) {
        distance = coordinates.sliderWidth;
      }

      if (
        distance >= 0 &&
        distance <= coordinates.sliderWidth &&
        newValue >= minValue &&
        sliderRef.current
      ) {
        sliderRef.current.style.width = `${distance}px`;
        handler(newValue);
      }
    },
    [
      sliderRef,
      coordinates.sliderWidth,
      coordinates.startLength,
      coordinates.startX,
      coordinates.stepPixels,
      handler,
      minValue,
      step,
    ]
  );

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      onMove(event.clientX);
    },
    [onMove]
  );

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      onMove(event.touches[0].clientX);
    },
    [onMove]
  );

  const onMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }, [onMouseMove]);

  const onTouchEnd = useCallback(() => {
    document.removeEventListener('touchmove', onTouchMove);
    document.removeEventListener('touchend', onTouchEnd);
  }, [onTouchMove]);

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      coordinates.startX = event.clientX;
      coordinates.startY = event.clientY;
      coordinates.startLength = sliderRef.current
        ? parseFloat(sliderRef.current.style.width)
        : 0;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [coordinates, onMouseMove, onMouseUp, sliderRef]
  );

  const onTouchStart = useCallback(
    (event: TouchEvent) => {
      coordinates.startX = event.touches[0].clientX;
      coordinates.startY = event.touches[0].clientY;
      coordinates.startLength = sliderRef.current
        ? parseFloat(sliderRef.current.style.width)
        : 0;

      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    },
    [coordinates, onTouchMove, onTouchEnd, sliderRef]
  );

  return {
    onSliderClick,
    onMouseDown,
    onTouchStart,
  };
};
