import { FC, ReactElement } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { CreditCalculator } from '@/components/CreditCalculator';
import { NavigateState } from '@/router/types';
import { CardBlank } from '@/shared/CardBlank';
import { MainButton } from '@/shared/MainButton';

interface Props {
  navigateTo?: string;
  navigateState?: NavigateState;
  className?: string;
  customButtonText?: ReactElement;
}

export const CreditCalculatorWidget: FC<Props> = ({
  navigateTo = 'cabinet',
  navigateState = '',
  className = '',
  customButtonText,
}) => {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate(`/${navigateTo}`, { state: navigateState });
  };

  const buttonText = customButtonText ?? (
    <FormattedMessage defaultMessage="Оформить заявку" />
  );

  return (
    <CardBlank className={cn(styles.card, className)}>
      <CreditCalculator />
      <div>
        <MainButton id="calculator-widget-submit" onClick={onSubmit}>
          {buttonText}
        </MainButton>
      </div>
    </CardBlank>
  );
};
