import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import LeftSpot from '../assets/mobile-spot-left.svg';
import RightSpot from '../assets/mobile-spot-right.svg';
import {
  LOAN_ANNUAL_EFFECTIVE_RATE_PERCENT,
  LOAN_DAY_RATE_MIN_PERCENT,
} from '@/common/constants/loan';
import { CreditCalculatorWidget } from '@/components/CreditCalculatorWidget';
import { DecorBlock } from '@/entities/HomePage/Banner/ui/DecorBlock';
import { CardBlank } from '@/shared/CardBlank';

const BannerMobile = () => (
  <section className={styles.section}>
    <div className={styles.module}>
      <DecorBlock className={styles.leftSpot}>
        <LeftSpot />
      </DecorBlock>
      <DecorBlock className={styles.rightSpot}>
        <RightSpot />
      </DecorBlock>
      <div className={styles.moduleInner}>
        <div className={styles.textWrapper}>
          <h1 className={styles.title}>
            <FormattedMessage defaultMessage="Финансовая свобода в одном кадре" />
          </h1>
          <h2 className={styles.subtitle}>
            <FormattedMessage defaultMessage="Минимум данных — максимум удобства " />
          </h2>
        </div>
        <div className={styles.main}>
          <CardBlank className={styles.bullet}>
            <div className={styles.fakeBulletText}>
              <h3 className={styles.title}>
                <FormattedMessage
                  defaultMessage="Первый микрокредит под {minRate}%"
                  values={{ minRate: LOAN_DAY_RATE_MIN_PERCENT }}
                />
              </h3>
              <span className={styles.subtitle}>
                <FormattedMessage
                  defaultMessage="ГЭСВ до {annualEffectiveRate}%"
                  values={{
                    annualEffectiveRate: LOAN_ANNUAL_EFFECTIVE_RATE_PERCENT,
                  }}
                />
              </span>
            </div>
          </CardBlank>
          <div className={styles.bulletText}>
            <h3 className={styles.title}>
              <FormattedMessage
                defaultMessage="Первый микрокредит под {minRate}%"
                values={{ minRate: LOAN_DAY_RATE_MIN_PERCENT }}
              />
            </h3>
            <span className={styles.subtitle}>
              <FormattedMessage
                defaultMessage="ГЭСВ до {annualEffectiveRate}%"
                values={{
                  annualEffectiveRate: LOAN_ANNUAL_EFFECTIVE_RATE_PERCENT,
                }}
              />
            </span>
          </div>
          <img
            className={styles.image}
            src="/static/images/banner-mobile.webp"
            alt="микрокредитный калькулятор"
          />
        </div>
      </div>
    </div>
    <CreditCalculatorWidget />
  </section>
);

export default BannerMobile;
