import { FormattedMessage } from 'react-intl';
import DocumentIcon from '@/common/svg/document-icon.svg';
import PhotoCamera from '@/common/svg/photo-camera.svg';
import WalletIcon from '@/common/svg/wallet-checked.svg';

export const content = [
  {
    order: '01',
    Icon: DocumentIcon,
    title: <FormattedMessage defaultMessage="Оставить заявку" />,
    description: <FormattedMessage defaultMessage="Вам нужны только ИИН, номер телефона и email" />,
  },
  {
    order: '02',
    Icon: PhotoCamera,
    title: <FormattedMessage defaultMessage="Сделать селфи" />,
    description: <FormattedMessage
      defaultMessage="Теперь не нужно заполнять длинные анкеты. Просто сделайте селфи, а остальное — за нами" />,
  },
  {
    order: '03',
    Icon: WalletIcon,
    title: <FormattedMessage defaultMessage="Получить деньги" />,
    description: <FormattedMessage
      defaultMessage="Быстрое зачисление денег на банковский счёт" />,
  }
];
