import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const Address = () => (
  <div className={styles.footerAddress}>
    <span>
      <FormattedMessage defaultMessage="ТОО «МФО «CreditNova»" />
    </span>
    <span>
      <FormattedMessage defaultMessage="Адрес" />
      :&nbsp;
      <span className={styles.textGray}>
        <FormattedMessage defaultMessage="г. Алматы, 050057 ул. Толе би, 101" />
      </span>
      ,&nbsp;
      <span className={styles.textGray}>
        <FormattedMessage defaultMessage="БЦ «Толе би», 3 этаж, блок D" />
      </span>
    </span>
    <span>
      <FormattedMessage defaultMessage="БИН" />
      :&nbsp;
      <span className={styles.textGray}>151140014481</span>
    </span>
    <span>
      <FormattedMessage defaultMessage="ОКЭД" />
      :&nbsp;
      <span className={styles.textGray}>64929</span>
    </span>
    <span>
      <FormattedMessage defaultMessage="Р/сч" />
      :&nbsp;
      <span className={styles.textGray}>KZ216017131000001158</span>
    </span>
    <span>
      <FormattedMessage defaultMessage="БИК" />
      :&nbsp;
      <span className={styles.textGray}>HSBKKZKX</span>
    </span>
  </div>
);
