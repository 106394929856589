import { HEADERS_JSON } from '@/common/api/constants';
import { PostUtmRequest } from '@/common/api/requests/app/types';
import { API_URL } from '@/config';

export const postUtm = async ({
  token,
  utm,
}: PostUtmRequest): Promise<Response | null> => {
  if (token && utm) {
    return await fetch(`${API_URL}/cabinet/utm`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, ...HEADERS_JSON },
      body: utm,
    });
  }
  return null;
};
