import styles from './styles.module.scss';
import { Address } from '@/entities/Footer/ui/Address';
import { BankCards } from '@/entities/Footer/ui/BankCards';
import { Contacts } from '@/entities/Footer/ui/Contacts';
import { License } from '@/entities/Footer/ui/License';
import { Logo } from '@/entities/Footer/ui/Logo';
import { Navigation } from '@/entities/Footer/ui/Navigation';

const FooterMobile = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contactsBlock}>
        <div className={styles.logoWrapper}>
          <Logo />
          <BankCards />
        </div>
        <Contacts />
      </div>
      <Navigation />
      <Address />
      <License />
    </footer>
  );
};

export default FooterMobile;
