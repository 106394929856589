// extracted by mini-css-extract-plugin
var _1 = "row__qQqLE";
var _2 = "slider__IpJId";
var _3 = "sliderContainer__zCTqr";
var _4 = "sliderWrapper__ZDqi1";
var _5 = "thumb__sYulG";
var _6 = "title__j8Sly";
var _7 = "total__BF8Ov";
export { _1 as "row", _2 as "slider", _3 as "sliderContainer", _4 as "sliderWrapper", _5 as "thumb", _6 as "title", _7 as "total" }
export default { "row": _1, "slider": _2, "sliderContainer": _3, "sliderWrapper": _4, "thumb": _5, "title": _6, "total": _7 }
