import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { CreditCalculatorWidget } from '@/components/CreditCalculatorWidget';
import { MobileImageModule } from '@/components/MobileImageModule';
import Image from '@/entities/HomePage/BannerBottom/assets/banner-bottom-mobile.webp';

const BannerBottomMobile = () => {
  return (
    <section className={styles.section}>
      <h2 className={styles.title}>
        <FormattedMessage defaultMessage="Мгновенная регистрация с помощью селфи" />
      </h2>
      <MobileImageModule
        Image={Image}
        imgAlt="mgnovennaya registraciya s pomoshchyu selfi"
      >
        <CreditCalculatorWidget />
      </MobileImageModule>
    </section>
  );
};

export default BannerBottomMobile;
