import styles from './styles.module.scss';
import { content } from '@/components/MoneyStepsFAQ/content';

export const MoneyStepsFAQ = () => (
  <div className={styles.moneyStepsWrapper}>
    {content.map(({ order, Icon, title, description }, index) => (
      <div key={index} className={styles.item}>
        <div className={styles.decor}>{order}</div>
        <div className={styles.titleWrapper}>
          <Icon />
          <h3 className={styles.itemTitle}>{title}</h3>
        </div>
        <p className={styles.description}>{description}</p>
      </div>
    ))}
  </div>
);
