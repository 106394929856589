import React, { FC, ReactElement, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import ThumbIcon from '@/common/svg/slider-thumb.svg';
import { formatAmount } from '@/common/utils/numbers';
import { useSlider } from '@/components/SliderChunk/hooks/useSlider';

interface SliderChunkProps {
  title: ReactElement;
  minValue: number;
  maxValue: number;
  handlerValue: (value: number) => void;
  initialValue?: number;
  sign?: string | ReactElement;
}

const THUMB_ID = 'thumb';

export const SliderChunk: FC<SliderChunkProps> = ({
  title,
  minValue,
  maxValue,
  handlerValue,
  initialValue,
  sign,
}) => {
  const sliderWrapperRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const defaultValue = initialValue ?? minValue;
  const chunksAmount = maxValue - minValue;

  const [value, setValue] = useState<number>(defaultValue);

  const handler = (value: number) => {
    setValue(value);
    handlerValue(value);
  };

  const { onSliderClick, onMouseDown, onTouchStart, chunkLength } = useSlider({
    sliderWrapperRef,
    sliderRef,
    minValue,
    maxValue,
    defaultValue,
    chunksAmount,
    handler,
    THUMB_ID,
  });

  return (
    <div>
      <div className={styles.row}>
        <span className={styles.title}>{title}</span>
        <span className={styles.total}>
          {formatAmount({ number: value })} {sign}
        </span>
      </div>
      <div className={styles.sliderContainer}>
        <div
          ref={sliderWrapperRef}
          className={styles.sliderWrapper}
          onClick={onSliderClick}
        >
          <div ref={sliderRef} className={styles.slider}>
            <div
              id={THUMB_ID}
              className={styles.thumb}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
            >
              <ThumbIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles.scaleContainer, styles.row)}>
        {!!chunkLength &&
          Array(chunksAmount + 1)
            .fill('')
            .map((_, index) => (
              <span
                key={index}
                className={styles.scale}
                style={{ left: chunkLength * index }}
              >
                {(minValue + index) % 5 === 0 ? minValue + index : '.'}
              </span>
            ))}
      </div>
    </div>
  );
};
