import { postUtm } from '@/common/api/requests/app/requests';
import { UTM_KEYS, UTM_SOURCE } from '@/common/utm/constants';

export const processUtm = async () => {
  const token = localStorage.getItem('token') || '';

  try {
    const search = decodeURI(location.search);
    const uriSearchParam = search.substring(1);
    const hasSource = uriSearchParam?.includes(UTM_SOURCE);

    if (hasSource) {
      const jsonFormat = `{"${uriSearchParam.replace(/=/g, '":"').replace(/&/g, '","')}"}`;

      const utm = Object.entries(JSON.parse(jsonFormat))
        .filter(([key]) => !!UTM_KEYS[key])
        ?.reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: value,
          };
        }, {});

      if (token) {
        await postUtm({ token, utm: JSON.stringify(utm) });
      } else {
        localStorage.setItem('utm', JSON.stringify(utm));
      }
    }
  } catch {
    console.warn('process utm error');
  }
};
