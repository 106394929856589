import styles from './styles.module.scss';
import MastercardIcon from '@/common/svg/mastercard.svg';
import VisaCardIcon from '@/common/svg/visa-card.svg';

export const BankCards = () => (
  <div className={styles.bankCards}>
    <VisaCardIcon />
    <div className={styles.cardBase}>
      <MastercardIcon />
    </div>
  </div>
);
