import { Route } from 'react-router-dom';
import { LayoutBase } from '@/entities/Layout/Base';
import { ErrorPage } from '@/pages/ErrorPage';
import { authLoader } from '@/router/loaders/authLoader';

export const signupRouter = (
  <Route
    loader={authLoader}
    element={<LayoutBase />}
    errorElement={<ErrorPage />}
  >
    <Route path="/lead-biometry" lazy={() => import('@/pages/LeadBiometry')} />
    <Route path="/loan-biometry" lazy={() => import('@/pages/LoanBiometry')} />
    <Route
      path="/profile-refill"
      lazy={() => import('@/pages/ProfileRefill')}
    />
    <Route path="/lead-apply" lazy={() => import('@/pages/LeadApply')} />
    <Route path="/lead-sign" lazy={() => import('@/pages/LeadSign')} />
    <Route
      path="/upload-statements"
      lazy={() => import('@/pages/UploadStatements')}
    />
    <Route path="/loan-purpose" lazy={() => import('@/pages/LoanPurpose')} />
    <Route path="/loan-submit" lazy={() => import('@/pages/LoanSubmit')} />
  </Route>
);
