import { fetchProfile } from '@/common/api/requests/profile/request';
import { ErrorMessageType } from '@/common/types/common';
import { ProfileProps } from '@/common/types/profile';

type ProfileFetcher = ({
  token,
  setErrorMessage,
}: {
  token: string;
  setErrorMessage: (message: ErrorMessageType) => void;
}) => Promise<ProfileProps | undefined>;

export const profileFetcher: ProfileFetcher = async ({
  token,
  setErrorMessage,
}) => {
  const response = await fetchProfile({ token, setErrorMessage });

  const profile: ProfileProps | undefined = (
    (await response?.json()) as { data: ProfileProps }
  )?.data;

  if (profile?.reject) {
    localStorage.setItem('rejectExpires', profile.reject.expires_at);
    window.location.replace('/rejected');
  }

  return profile;
};
