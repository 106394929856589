import { FC, ReactElement, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { DocumentIcon } from '@/common/icons/DocumentIcon';
import DocumentDownloadIcon from '@/common/svg/document-download.svg';
import { calcFileSizeMb, getFileSize } from '@/common/utils/files';

export interface DownloadableItemProps {
  link: string;
  title: ReactElement | string;
  size?: number | null;
}

export const DownloadableItem: FC<DownloadableItemProps> = ({
  link,
  title,
  size,
}) => {
  const [fileSizeMb, setFileSizeMb] = useState<number | null>(null);

  useMemo(async () => {
    if (size) {
      setFileSizeMb(calcFileSizeMb(size));
    } else {
      const fileSizeBytes = await getFileSize(link);
      setFileSizeMb(calcFileSizeMb(fileSizeBytes));
    }
  }, [link, size]);

  return (
    <a className={styles.row} href={link} target="_blank" rel="noreferrer">
      <span className={styles.iconWrapper}>
        <DocumentIcon />
      </span>
      <span className={styles.textWrapper}>
        <span className={styles.title}>{title}</span>
        {fileSizeMb && <span className={styles.caption}>{fileSizeMb} MB</span>}
      </span>
      <DocumentDownloadIcon />
    </a>
  );
};
