import { Outlet } from 'react-router';
import styles from './styles.module.scss';
import { ModalError } from '@/components/ModalError';
import { Footer } from '@/entities/Footer';
import { Header } from '@/entities/Header';
import { useScrollToTop } from '@/entities/Layout/useScrollToTop';

export const LayoutBase = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <div className={styles.contentWrapper}>
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
      <ModalError />
    </>
  );
};
