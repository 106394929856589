// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-ring {
    position: relative;
    width: 100%;
    height: 100%;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 10px solid var(--brand-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--brand-color) transparent transparent transparent;
}

.lds-ring.narrow div {
    border-width: 4px;
}

.lds-ring.light div {
    border-color: var(--light-color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/shared/Spinner/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,8DAA8D;IAC9D,oEAAoE;AACxE;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oEAAoE;AACxE;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".lds-ring {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.lds-ring div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border: 10px solid var(--brand-color);\n    border-radius: 50%;\n    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-color: var(--brand-color) transparent transparent transparent;\n}\n\n.lds-ring.narrow div {\n    border-width: 4px;\n}\n\n.lds-ring.light div {\n    border-color: var(--light-color) transparent transparent transparent;\n}\n\n.lds-ring div:nth-child(1) {\n    animation-delay: -0.45s;\n}\n\n.lds-ring div:nth-child(2) {\n    animation-delay: -0.3s;\n}\n\n.lds-ring div:nth-child(3) {\n    animation-delay: -0.15s;\n}\n\n@keyframes lds-ring {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
