import { FC } from 'react';
import { IconProps } from '@/common/icons/types';
import { BLACK_MUTE_COLOR } from '@/styles/colorsJS';

export const CloseCrossIcon: FC<IconProps> = ({ color = BLACK_MUTE_COLOR }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="close">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42952 5.42952C6.00222 4.85683 6.93074 4.85683 7.50344 5.42952L12 9.92608L16.4966 5.42952C17.0693 4.85683 17.9978 4.85683 18.5705 5.42952C19.1432 6.00222 19.1432 6.93074 18.5705 7.50344L14.0739 12L18.5705 16.4966C19.1432 17.0693 19.1432 17.9978 18.5705 18.5705C17.9978 19.1432 17.0693 19.1432 16.4966 18.5705L12 14.0739L7.50344 18.5705C6.93074 19.1432 6.00222 19.1432 5.42952 18.5705C4.85683 17.9978 4.85683 17.0693 5.42952 16.4966L9.92608 12L5.42952 7.50344C4.85683 6.93074 4.85683 6.00222 5.42952 5.42952Z"
        fill={color}
      />
    </g>
  </svg>
);
