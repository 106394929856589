import { useContext } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { AppContext } from '@/common/hooks/useAppContext';
import { Locales } from '@/lang/types';

export const LangSwitcher = () => {
  const { lang, setLang } = useContext(AppContext);
  const isKazLang = lang === Locales.kz;

  const onChangeLang = () => {
    setLang();
    const nextValue = lang === Locales.ru ? Locales.kz : Locales.ru;
    localStorage.setItem('lang', nextValue);
  };

  return (
    <button className={styles.langSwitcher} onClick={onChangeLang}>
      <span className={cn(styles.label, { [styles.active]: isKazLang })}>қаз</span>
      <span className={cn(styles.label, { [styles.active]: !isKazLang })}>рус</span>
      <div className={cn(styles.thumb, { [styles.activeLeft]: isKazLang }, { [styles.activeRight]: !isKazLang })} />
    </button>
  );
};
