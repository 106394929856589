import { FC, ReactElement } from 'react';
import styles from './styles.module.scss';
import { IconProps } from '@/common/icons/types';

interface Props {
  Icon: string | FC<IconProps>;
  title: ReactElement;
  description: ReactElement;
}

export const BenefitItem: FC<Props> = ({ Icon, title, description }) => {
  return (
    <div className={styles.benefitItem}>
      <div className={styles.titleWrapper}>
        <div>
          <Icon />
        </div>
        <h3 className={styles.title}>{title}</h3>
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  );
};
