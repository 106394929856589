import { lazy, Suspense } from 'react';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import BannerMobile from '@/entities/HomePage/Banner/mobile';

const BannerDesktop = lazy(() => import('./desktop'));

export const Banner = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile && <BannerMobile />}
      {!isMobile && (
        <Suspense>
          <BannerDesktop />
        </Suspense>
      )}
    </>
  );
};
