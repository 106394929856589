export const UTM_SOURCE = 'utm_source';

export const UTM_KEYS: Record<string, string> = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  addon: 'addon',
  web_id: 'web_id',
  lead_it: 'lead_it',
  transaction_id: 'transaction_id',
  offer_id: 'offer_id',
  click_id: 'click_id',
  cpa_click_id: 'cpa_click_id',
  action_id: 'action_id',
  gclid: 'gclid',
  wmid: 'wmid',
  cpa_wm_id: 'cpa_wm_id',
  aff_id: 'aff_id',
  aff_sub: 'aff_sub',
};
