import { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import styles from './styles.module.scss';
import { generateRandomSize } from '@/common/utils/numbers';

interface Props {
  length?: number;
  height?: number;
  fixedWidthAbsolute?: number;
  fixedWidthRelative?: number;
  className?: string;
}

export const SkeletonList: FC<Props> = ({
  length = 1,
  height = 24,
  fixedWidthAbsolute,
  fixedWidthRelative = 100,
  className,
}) => {
  return (
    <div className={styles.skeletonWrapper}>
      {Array(length)
        .fill('')
        .map((_, index) => {
          const randomWidth = generateRandomSize({ max: 80, min: 30 });
          const width = fixedWidthAbsolute
            ? `${fixedWidthAbsolute}px`
            : fixedWidthRelative
              ? `${fixedWidthRelative}%`
              : `${randomWidth}%`;
          return (
            <Skeleton
              key={index}
              width={width}
              height={height}
              variant="rounded"
              animation="wave"
              className={className}
            />
          );
        })}
    </div>
  );
};
