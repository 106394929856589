import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS, STALE_TIME_SHORT } from '@/common/api/constants';
import { profileFetcher } from '@/common/api/requests/profile/profileFetcher';
import { RefetchCommonType } from '@/common/api/types';
import { AppContext } from '@/common/hooks/useAppContext';
import { ProfileProps } from '@/common/types/profile';

type UseFetchProfile<T> = (refetchOnWindowFocus?: boolean) => {
  loadingProfile: boolean;
  isFetchedProfile: boolean;
  refetch: RefetchCommonType<T>;
  profile?: ProfileProps;
};

export const useFetchProfile: UseFetchProfile<ProfileProps | undefined> = (
  refetchOnWindowFocus = true
) => {
  const token = localStorage.getItem('token') || '';
  const { setErrorMessage } = useContext(AppContext);

  const { data, refetch, isLoading, isFetched } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_PROFILE, token, setErrorMessage],
    queryFn: () => profileFetcher({ token, setErrorMessage }),
    enabled: !!token,
    staleTime: STALE_TIME_SHORT,
    refetchOnWindowFocus,
  });

  return {
    loadingProfile: isLoading,
    isFetchedProfile: isFetched,
    refetch,
    profile: data,
  };
};
