import { memo } from 'react';
import styles from './styles.module.scss';
import { useFetchProfile } from '@/common/api/requests/profile/useFetchProfile';
import { SkeletonList } from '@/shared/Skeleton';
import { PROFILE_CARD_HEIGHT } from '@/styles/constantsJS';

export const ProfileCard = memo(() => {
  const { profile, loadingProfile } = useFetchProfile();
  return (
    <div className={styles.profileWrapper}>
      {loadingProfile && <SkeletonList height={PROFILE_CARD_HEIGHT} />}
      {!loadingProfile && profile && (
        <>
          <div className={styles.avatarWrapper}>
            <img
              className={styles.avatar}
              src={profile.avatar.url}
              alt="profile image"
            />
          </div>
          <div>
            <h2 className={styles.title}>{profile.name}</h2>
            <h3 className="gray-font">{profile.email}</h3>
          </div>
        </>
      )}
    </div>
  );
});

ProfileCard.displayName = 'ProfileName';
