import { FC, ReactElement, useRef, useState } from 'react';
import styles from './styles.module.scss';
import ThumbIcon from '@/common/svg/slider-thumb.svg';
import { formatAmount } from '@/common/utils/numbers';
import { useSlider } from '@/components/SliderSmooth/hooks/useSlider';

interface SliderSmoothProps {
  title: ReactElement;
  minValue: number;
  maxValue: number;
  handlerValue: (value: number) => void;
  initialValue?: number;
  step?: number;
  sign?: string | ReactElement;
}

const THUMB_ID = 'thumb';

export const SliderSmooth: FC<SliderSmoothProps> = ({
  title,
  minValue,
  maxValue,
  handlerValue,
  initialValue = 0,
  step = 1000,
  sign,
}) => {
  const sliderWrapperRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const thumbRef = useRef<HTMLDivElement | null>(null);

  const [value, setValue] = useState<number>(initialValue);

  const handler = (value: number) => {
    setValue(value);
    handlerValue(value);
  };

  const { onSliderClick, onMouseDown, onTouchStart } = useSlider({
    sliderWrapperRef,
    sliderRef,
    maxValue,
    minValue,
    initialValue,
    step,
    handler,
    THUMB_ID,
  });

  return (
    <div>
      <div className={styles.row}>
        <span className={styles.title}>{title}</span>
        <span className={styles.total}>
          {formatAmount({ number: value })} {sign}
        </span>
      </div>
      <div className={styles.sliderContainer}>
        <div
          ref={sliderWrapperRef}
          className={styles.sliderWrapper}
          onClick={onSliderClick}
        >
          <div ref={sliderRef} className={styles.slider}>
            <div
              id={THUMB_ID}
              ref={thumbRef}
              className={styles.thumb}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
            >
              <ThumbIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <span>
          {formatAmount({ number: minValue })} {sign}
        </span>
        <span>
          {formatAmount({ number: maxValue })} {sign}
        </span>
      </div>
    </div>
  );
};
