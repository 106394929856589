import { Route } from 'react-router-dom';
import { LayoutCabinet } from '@/entities/Layout/Cabinet';
import { ErrorPage } from '@/pages/ErrorPage';
import { authLoader } from '@/router/loaders/authLoader';

export const cabinetRouter = (
  <Route
    loader={authLoader}
    element={<LayoutCabinet />}
    errorElement={<ErrorPage />}
  >
    <Route path="/cabinet" lazy={() => import('@/pages/Cabinet')} />
    <Route path="/profile" lazy={() => import('@/pages/Profile')} />
    <Route path="/history" lazy={() => import('@/pages/History')} />
  </Route>
);
