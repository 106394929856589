import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { DocumentsModal } from '@/components/DocumentsModal';

export const Navigation = () => {
  const [showDocuments, setShowDocuments] = useState(false);
  const handleShowDocuments = () => {
    setShowDocuments((prev) => !prev);
  };
  return (
    <nav className={styles.footerNav}>
      <Link
        id="footer-link-loan-conditions"
        className={styles.link}
        to="/usloviya-predostavleniya-mikrokreditov"
      >
        <FormattedMessage defaultMessage="Условия предоставления микрокредитов" />
      </Link>
      <Link id="footer-link-compliant" className={styles.link} to="/complaint">
        <FormattedMessage defaultMessage="Оставить жалобу" />
      </Link>
      <Link
        id="footer-link-how-get-money"
        className={styles.link}
        to="/kak-poluchit-dengi"
      >
        <FormattedMessage defaultMessage="Как получить деньги" />
      </Link>
      <Link
        id="footer-link-how-to-repayment"
        className={styles.link}
        to="/sposoby-oplaty"
      >
        <FormattedMessage defaultMessage="Как вернуть деньги" />
      </Link>
      <Link id="footer-link-faq" className={styles.link} to="/vopros-otvet">
        <FormattedMessage defaultMessage="Вопрос - ответ (помощь)" />
      </Link>
      <Link id="footer-link-contacts" className={styles.link} to="/kontakty">
        <FormattedMessage defaultMessage="Контакты" />
      </Link>
      <button
        id="footer-link-documnets"
        className={styles.link}
        onClick={handleShowDocuments}
      >
        <FormattedMessage defaultMessage="Документы" />
      </button>
      <DocumentsModal show={showDocuments} onClose={handleShowDocuments} />
    </nav>
  );
};
