import { FC, ReactElement } from 'react';
import styles from './styles.module.scss';

interface Props {
  text: ReactElement;
}

export const Tag: FC<Props> = ({ text }) => {
  return (
    <h4 className={styles.tag}>
      {text}
    </h4>
  );
};
