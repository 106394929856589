import { FC, lazy, Suspense } from 'react';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import MobileHeader from '@/entities/Header/mobile';

const DesktopHeader = lazy(() => import('./desktop'));

interface Props {
  showButton?: boolean;
}

export const Header: FC<Props> = ({ showButton = true }) => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile && <MobileHeader />}
      {!isMobile && (
        <Suspense>
          <DesktopHeader showButton={showButton} />
        </Suspense>
      )}
    </>
  );
};
