// extracted by mini-css-extract-plugin
var _1 = "bullet__urEGn";
var _2 = "bulletText__zVeNN";
var _3 = "fakeBulletText__UixSj";
var _4 = "image__SLcsR";
var _5 = "leftSpot__DocQF";
var _6 = "main__JenLb";
var _7 = "module__c6FZE";
var _8 = "moduleInner__vs447";
var _9 = "rightSpot__NXkiW";
var _a = "section__MV7WU";
var _b = "subtitle__lLphR";
var _c = "textWrapper__ozPBV";
var _d = "title__hYLSD";
export { _1 as "bullet", _2 as "bulletText", _3 as "fakeBulletText", _4 as "image", _5 as "leftSpot", _6 as "main", _7 as "module", _8 as "moduleInner", _9 as "rightSpot", _a as "section", _b as "subtitle", _c as "textWrapper", _d as "title" }
export default { "bullet": _1, "bulletText": _2, "fakeBulletText": _3, "image": _4, "leftSpot": _5, "main": _6, "module": _7, "moduleInner": _8, "rightSpot": _9, "section": _a, "subtitle": _b, "textWrapper": _c, "title": _d }
