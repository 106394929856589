import { createContext, useMemo, useState } from 'react';
import { ErrorMessageType } from '@/common/types/common';
import { Locales } from '@/lang/types';

interface AppContextProps {
  lang: Locales;
  setLang: () => void;
  errorMessage: ErrorMessageType;
  setErrorMessage: (message: ErrorMessageType) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const localStoreLang = (localStorage.getItem('lang') as Locales) || Locales.ru;

export const AppContext = createContext<AppContextProps>({
  lang: localStoreLang,
  setLang: () => {},
  errorMessage: null,
  setErrorMessage: () => {},
  loading: false,
  setLoading: () => {},
});

type UseAppContext = () => {
  lang: Locales;
  initialAppContext: AppContextProps;
};

export const useAppContext: UseAppContext = () => {
  const [lang, setLang] = useState<Locales>(localStoreLang);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null);
  const [loading, setLoading] = useState(false);

  const initialAppContext: AppContextProps = useMemo(() => {
    return {
      lang,
      setLang: () => {
        setLang((prev) => {
          return prev === Locales.ru ? Locales.kz : Locales.ru;
        });
      },
      errorMessage: errorMessage,
      setErrorMessage: (message) => setErrorMessage(message),
      loading,
      setLoading: (value) => setLoading(value),
    };
  }, [lang, errorMessage, loading]);

  return {
    lang,
    initialAppContext,
  };
};
